import { DashboardRouter } from "../dashboard.router";

export class ActionLogsRouter {
    
    static getRoute(): string {
	console.log(`${DashboardRouter.getRoute()}/logs`);
        return `${DashboardRouter.getRoute()}/logs`
    }

}
