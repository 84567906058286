import ErrorDialog, {
    IErrorInformation,
} from "../../../components/errorDialog";
import { Grid, TextField, Theme } from "@mui/material";
import { HospitalCenterQuery, RegionsQuery } from "__generated__/graphql";
import InformativeDialog, {
    IInformative,
} from "../../../components/informativeDialog";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithApolloClient, withApollo } from "@apollo/react-hoc";
import { WithTranslation, withTranslation } from "react-i18next";

import CreateSomethingWrapper from "../../../components/createSomethingWrapper";
import { GraphQLError } from "graphql";
import { HospitalCenterRequests } from "../../../apollo";
import { HospitalCenterUpdate } from "../../../rebrain-irm-model";
import React from "react";
import { RegionRequests } from "apollo/RegionRequests";
import RegionSelector from "components/regions/regionSelector";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        title: {
            fontWeight: "bolder",
        },
        gradient: {},
    });

type HospitalCenter = HospitalCenterQuery["hospitalCenter"];
type Regions = RegionsQuery["regions"];
type Region = Regions[0];

interface IState {
    hospital?: HospitalCenter;
    regions?: Region[];
    selectedRegionId?: number;
    fieldErrors?: { [key: string]: string };

    error?: IErrorInformation;
    information?: IInformative;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps<{ id: string }>,
        WithTranslation,
        WithApolloClient<{}> {}

class UpdateHospitalCenterPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        const id = Number(this.props.match.params.id);

        if (isNaN(id)) {
            this.setState({
                error: {
                    title: "An error occurred with the url",
                    message: "Please retry later",
                    onClose: this.props.history.goBack,
                },
            });
        } else {
            this.__loadOriginHospital(id);
            this.__loadRegions();
        }
    };

    render = () => {
        return (
            <CreateSomethingWrapper
                title={this.props.t("update hospital center")}
                onPressCancel={this.__onPressCancel}
                onPressCreate={this.__onPressCreate}
            >
                {this.__renderForm()}
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => {}}
                />
            </CreateSomethingWrapper>
        );
    };

    __renderForm = () => {
        return (
            <form>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={this.state.fieldErrors?.name !== undefined}
                            helperText={this.state.fieldErrors?.name}
                            id="name"
                            label={this.props.t("name")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            value={this.state.hospital?.name}
                            onChange={(e) =>
                                this.__onChange("name", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} />

                    <Grid item xs={12}>
                        <TextField
                            error={
                                this.state.fieldErrors?.address !== undefined
                            }
                            helperText={this.state.fieldErrors?.address}
                            multiline
                            rows={3}
                            id="address"
                            label={this.props.t("address")}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.hospital?.address}
                            onChange={(e) =>
                                this.__onChange("address", e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RegionSelector
                            availableRegions={this.state.regions ?? []}
                            initialRegionId={this.state.hospital?.regionId}
                            onRegionChange={this.__onRegionChange}
                            onRegionRemove={this.__onRegionRemove}
                        />
                    </Grid>

                    <Grid item xs={1} sm={6} />
                </Grid>
            </form>
        );
    };

    __onRegionChange = async (regionId: number) => {
        this.setState((state) => ({ ...state, selectedRegionId: regionId }));
    };

    __onRegionRemove = async () => {
        this.setState((state) => ({ ...state, selectedRegionId: undefined }));
    };

    __onChange = (field: keyof HospitalCenterUpdate, value: any) => {
        const hospital = this.state.hospital;

        if (hospital) {
            (hospital[field] as any) = value;
            this.setState({ hospital });
        }
    };

    __onPressCancel = () => {
        this.props.history.goBack();
    };
    __onPressCreate = () => {
        const hospital = this.state.hospital;
        const errors: { [key: string]: string } = {};

        if (!hospital) return;
        const updateData = {
            ...hospital,
            regionId: this.state.selectedRegionId,
        };

        if (!updateData.name || updateData.name.length <= 0)
            errors.name = this.props.t("thanks to set a valid name");
        if (!updateData.address || updateData.address.length <= 0)
            errors.address = this.props.t("thanks to set a valid address");
        if (Object.keys(errors).length > 0)
            return this.setState({ fieldErrors: errors });

        this.setState({
            fieldErrors: undefined,
            information: {
                title: this.props.t("updating"),
                showProgress: true,
                message: `${this.props.t("updating")}...`,
            },
        });

        HospitalCenterRequests.updateHospitalCenter(updateData)
            .then((_) => {
                this.setState({
                    information: {
                        title: `${this.props.t("hospital updated")}.`,
                        message: `${this.props.t(
                            "hospital have been created"
                        )} !`,
                        onClose: () => this.props.history.goBack(),
                    },
                });
                // setTimeout(this.props.history.goBack, 1500) cela créait la redirection en trop
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t("error"),
                        message: err.message,
                    },
                });
            });
    };

    __loadOriginHospital = async (idHospitalCenter: number) => {
        this.setState({
            information: {
                title: this.props.t("loading"),
                showProgress: true,
            },
        });

        HospitalCenterRequests.hospitalCenter(idHospitalCenter, "no-cache")
            .then((hospital) => {
                this.setState({
                    information: undefined,
                    hospital,
                });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t("error while loading"),
                        message: err.message,
                    },
                });
            });
    };

    __loadRegions = async () => {
        this.setState({
            information: {
                title: this.props.t("generic.msg.loading"),
                showProgress: true,
            },
        });
        try {
            const { data: regions } = await RegionRequests.regions(
                "cache-first"
            );
            if (regions.regions) {
                this.setState((prevState) => ({
                    ...prevState,
                    regions: regions.regions,
                }));
            }
        } catch (err: unknown) {
            if (err instanceof GraphQLError) {
                this.setState({
                    error: {
                        title: this.props.t("generic.msg.error"),
                        message: err.message,
                    },
                });
            } else {
                this.setState({
                    error: {
                        title: this.props.t("generic.msg.error"),
                        message: `Unknown Error: ${err}`,
                    },
                });
            }
        } finally {
            this.setState({ information: undefined });
        }
    };
}

export default withTranslation()(
    withApollo(withRouter(withStyles(styles)(UpdateHospitalCenterPage)) as any)
);
