import ConfirmationDialog, {
    ConfirmationInformation,
} from 'components/confirmationDialog';
import { useCallback, useMemo, useState } from 'react';
import { useRegions, useRemoveRegion } from 'apollo/RegionRequests';

import { RegionTableUi } from './regionTableUi';
import { RegionsQuery } from '__generated__/graphql';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Region = RegionsQuery['regions'][0];
type RegionTableProps = {
    onRegionClicked?: (region: Region) => void;
    onRegionDeleted?: (region: Region) => void;
};

/** This table fetches and displays the list of all regions.
 *
 * It shows the region name creation/modification dates, number of hospitals and commercials and
 * also shows an action button that removes the corresponding region from database.
 *
 * This deletion is permanent and its protected by a confirmation dialog.
 *
 * @param props.onRegionClicked A callback that is called when a region ROW is clicked.
 * @param props.onRegionDeleted A callback that is called after a region was deleted.
 *
 */
export default function RegionTable(props: RegionTableProps) {
    const { t } = useTranslation();
    const { data } = useRegions();
    const [removeRegion] = useRemoveRegion();
    const deleteRegion = useCallback(
        async (region: Region) => {
            const { data: removeData } = await removeRegion({
                variables: { regionId: region._id },
            });
            props?.onRegionDeleted?.(region);
            console.log('deleteRegion success = ', removeData?.removeRegion);
        },
        [props.onRegionDeleted, removeRegion]
    );

    const [confirmation, setConfirmation] = useState<ConfirmationInformation>();
    const onDeleteClicked = useCallback(
        (region: Region) => {
            setConfirmation({
                title: t('regions.delete.confirmationTitle', {
                    regionName: region.name,
                }),
                message: t('regions.delete.confirmationMessage', {
                    regionName: region.name,
                }),
                onClickAccept: () => {
                    deleteRegion(region);
                    setConfirmation(undefined);
                },
                onClickCancel: () => setConfirmation(undefined),
            });
        },
        [deleteRegion, t]
    );

    const regions = useMemo(
        () => {
            const _regions = data?.regions;
            _regions?.slice()?.sort(
                (a, b) =>
                    new Date(b.lastUpdate).getTime() -
                    new Date(a.lastUpdate).getTime()
            );
            return _regions;
        },
        [data?.regions]
    );

    if (regions == null || regions.length === 0) {
        return (
            <Typography variant="body1">{t('regions.table.empty')}</Typography>
        );
    }

    return (
        <>
            <ConfirmationDialog
                confirmation={confirmation}
                onClose={() => setConfirmation(undefined)}
            />
            <RegionTableUi
                regions={regions}
                onDeleteClicked={onDeleteClicked}
                onRowClicked={props.onRegionClicked}
            />
        </>
    );
}
