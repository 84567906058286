import { FetchPolicy, gql } from "apollo-boost";
import { Client } from ".";
import { Auth, User, UserCreate, UserUpdate } from "../rebrain-irm-model";
import { useQuery } from "@apollo/react-hoc";

 const MLogin = gql`
	mutation login($authentication: UserLoginSchema!) {
		login(authentication: $authentication) {
			_id
			email
		}
	} 
`;


 interface MLoginArgs
{
	authentication:	Auth;
}

 interface MLoginData
{
	login:	User;
}

const QBasicMe = gql`
	query me {
		me {
			lastname
			email
			firstname
			role
		}
	}
`;

interface MeData {
	me: User;
}

 const MLogout = gql`
	mutation logout {
		logout
	}
`

interface MLogoutData {
	logout: boolean;
}

 const QUsers = gql`
	query users {
		users {
			_id
			lastname
			firstname
			email
			role
			hospitalCenter {
				name
			}
			status
		}
	}
`;

 interface UsersData {
	users:	Array<User>;
}

 const MUpdateUser = gql`
	mutation updateUser($updateUser: UserUpdateSchema!) {
		updateUser(user: $updateUser) {
			_id
			firstname
			lastname
		}
	}
`
 interface MUpdateUserArgs {
	updateUser: UserUpdate;
}
 interface MUpdateUserData {
	updateUser: User;
}


 const MCreateUser = gql`
	mutation createUser($createUser: UserCreateSchema!) {
		createUser(user: $createUser) {
			_id
			email
		}
	} 
`
 interface MCreateUserArgs {
	createUser: UserCreate;
}
 interface MCreateUserData {
	createUser: User;
}
const MDeleteUser = gql`
	mutation deleteUser($idUser: String!) {
		deleteUser(idUser: $idUser)
	}
`
interface MDeleteUserArgs {
	idUser:	string
}
interface MDeleteUserData {
	deleteUser: boolean;
}



 const QUser = gql`
	query user ($idUser: String!) {
		user(idUser: $idUser) {
			_id
			lastname
			firstname
			email
			role
			hospitalCenter {
				_id
			}
		}
	}
`

 interface UserArgs {
	idUser:	string;
}
 interface UserData {
	user: User;
}


 const MResetPassword = gql`
	mutation resetPassword($token: String!, $password: String!) {
		resetPassword(password: $password, token: $token)
	}
`
 interface ResetPasswordArgs {
	token:	string;
	password:	string;
}
 interface ResetPasswordData {
	resetPassword: boolean;
}

 const MForgottenPassword = gql`
	mutation forgottenPassword($email: String!){
		forgottenPasword(email: $email)
	}
`

 interface ForgottenPasswordArgs {
	email: String;
}
 interface ForgottenPasswordData {
	forgottenPassword: boolean
}



 interface SendConfirmationMailArgs {
	idUser: number;
}

export class UserRequests {


	public static async createUser(createUser: UserCreate): Promise<User> {
		return (await Client.mutate<MCreateUserData, MCreateUserArgs>({
			mutation: MCreateUser,
			variables: {
				createUser
			},
		})).data!.createUser
	}

	public static async deleteUser(idUser: string): Promise<boolean> {
		return (await Client.mutate<MDeleteUserData, MDeleteUserArgs>({
			mutation: MDeleteUser,
			variables: {
				idUser
			},
		})).data!.deleteUser
	}

	public static async login(authentication: Auth): Promise<User> {
		return (await Client.mutate<MLoginData, MLoginArgs>({
			mutation: MLogin,
			variables: {
				authentication
			},
		})).data!.login
	}

	public static async logout(): Promise<boolean> {
		const l = (await Client.mutate<MLogoutData>({
			mutation: MLogout,
		})).data!.logout
		await Client.resetStore()
		return l
	}

	public static async forgottenPassword(mail: string): Promise<boolean> {
		return (await Client.mutate<ForgottenPasswordData, ForgottenPasswordArgs>({
			mutation: MForgottenPassword,
			variables: {
				email: mail
			},
		})).data!.forgottenPassword
	}

	public static async resetPassword(token: string, password: string): Promise<boolean> {
		return (await Client.mutate<ResetPasswordData, ResetPasswordArgs>({
			mutation: MResetPassword,
			variables: {
				token,
				password
			},
		})).data!.resetPassword
	}

	/**
	 * Ask server to send confirmation mail
	 * @param input Variable
	 */
	public static async sendConfirmationMail(input: SendConfirmationMailArgs) {
		return await Client.mutate<any, SendConfirmationMailArgs>({
			mutation: gql`
				mutation askSendConfirmationMail($idUser: Float!){
					askSendConfirmationMail(idUser: $idUser)
				}
			`,
			variables: input,
		})
	}

	/**
	 * Get my basic information
	 */
	public static async meBasic(fetchPolicy?: FetchPolicy): Promise<User> {
		return (await Client.query<MeData>({
			query: QBasicMe,
			fetchPolicy
		})).data.me
	}

	/**
	 * Get all users
	 */
	public static async users(fetchPolicy?: FetchPolicy): Promise<Array<User>> {
		return (await Client.query<UsersData>({
			query: QUsers,
			fetchPolicy
		})).data.users
	}

	public static async user(idUser: string, fetchPolicy?: FetchPolicy): Promise<User> {
		return (await Client.query<UserData, UserArgs>({
			query: QUser,
			variables: {
				idUser
			},
			fetchPolicy
		})).data.user
	}

	public static async updateUser(updateUser: UserUpdate): Promise<User> {
		return (await Client.mutate<MUpdateUserData, MUpdateUserArgs>({
			mutation: MUpdateUser,
			variables: {
				updateUser
			},
		})).data!.updateUser
	}

}


export function useMe(){
	return useQuery(QBasicMe);
}

export function useIsAdmin() {
    const { data, loading } = useMe();
    return {
        isAdmin: data?.me.role === "ADMIN",
        loading,
    };
}
