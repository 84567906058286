import { Box, Typography } from '@mui/material';

import CircularProgress from "@mui/material/CircularProgress";
import CreateCommercialForm from 'components/commercials/createCommercialForm';
import CreateRegionForm from 'components/regions/createRegionForm';
import RegionIcon from 'components/regions/regionIcon';
import RegionTable from 'components/regions/regionTable';
import RegionsCommercialTable from 'components/commercials/commercialTable';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAdmin } from 'apollo';
import { useHistory } from 'react-router-dom';

export default function RegionsPage() {
    const { t } = useTranslation();
    const { openInformativeSnackbar } = useSnackbar();
    const [selectedRegionId, setSelectedRegionId] = useState<number>();
    const [selectedRegionName, setSelectedRegionName] = useState<string>();

    const { isAdmin, loading: loadingIsAdmin } = useIsAdmin();
    const history = useHistory();
    useEffect(() => {
        console.log("isAdmin", isAdmin);
        if (!isAdmin && !loadingIsAdmin) {
            history.push("/");
        }
    }, [isAdmin, history, loadingIsAdmin]);

    const onRegionClicked = (region: { _id: number; name: string }) => {
        setSelectedRegionId(region._id);
        setSelectedRegionName(region.name);
    };

    const onRegionAdded = (region: { _id: number; name: string }) => {
        setSelectedRegionId(region._id);
        setSelectedRegionName(region.name);
    };

    const onRegionDeleted = (region: { name: string; _id: number }) => {
        if (selectedRegionId == region._id) {
            setSelectedRegionId(undefined);
            setSelectedRegionName(undefined);
        }
        openInformativeSnackbar({
            message: t('regions.deleted', {
                regionName: region.name,
            }),
            icon: <RegionIcon />,
        });
    };

    const onCommercialRemovedFromRegion = (commercial: { email: string }) => {
        openInformativeSnackbar({
            message: t('commercials.delete.success', {
                commercialEmail: commercial.email,
                regionName: selectedRegionName,
            }),
            icon: <RegionIcon />,
        });
    };

    if (loadingIsAdmin) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{ m: 10 }}>
            <Typography variant="h2" gutterBottom sx={{ textAlign: 'center' }}>
                {t('regions.title')}
            </Typography>
            <Typography variant="h3">{t('regions.list.title')}</Typography>
            <Box sx={{ mt: 5 }}>
                <RegionTable
                    onRegionClicked={onRegionClicked}
                    onRegionDeleted={onRegionDeleted}
                />
            </Box>
            <Box sx={{ m: 10 }}>
                <CreateRegionForm onRegionAdded={onRegionAdded} />
            </Box>
            {selectedRegionName != null && (
                <Typography variant="h3" key="regions-commercials-title">
                    {t('commercials.title', {
                        regionName: selectedRegionName,
                    })}
                </Typography>
            )}
            {selectedRegionName == null && (
                <Typography variant="h3" key="regions-commercials-title">
                    {t('commercials.emptyTitle')}
                </Typography>
            )}
            {selectedRegionId && (
                <Box sx={{ mt: 5 }}>
                    <RegionsCommercialTable
                        regionId={selectedRegionId}
                        regionName={selectedRegionName ?? ''}
                        onCommercialRemovedFromRegion={
                            onCommercialRemovedFromRegion
                        }
                    />
                </Box>
            )}
            <Box sx={{ m: 10 }}>
                <CreateCommercialForm
                    regionId={selectedRegionId}
                    regionName={selectedRegionName}
                />
            </Box>
        </Box>
    );
}
