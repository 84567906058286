import React from 'react'
import { Grid, Box, Theme, Paper, Button, Typography, CircularProgress, Divider } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import ErrorText from './errorText';

const styles = (theme: Theme) => createStyles({
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	}
});

interface IProps extends WithStyles<typeof styles> {
	title?:	string;

	errorMessage?: string;

	onPressCreate?:	() => void;
	onPressCancel?:	() => void;

	loading?:	boolean;

	validateText?:	string;
	cancelText?:	string;

	children?	:	JSX.Element | JSX.Element[]
}

interface IState {
}


class CreateSomethingWrapper extends React.Component<IProps, IState> {


	constructor(props: IProps) {
		super(props);
		this.state = {
		}
	}

	render = () => {

		return (
			<Grid container justifyContent="center" alignItems="center">
				<Grid item sm={6} xs={11}>
					<Paper>
						<Box m={1} p={3}>
							{this.__renderContent()}
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}

	__renderContent = () => {
		const classes = this.props.classes;

		return (
			<Grid
				spacing={2}
				container>
				<Grid item xs={12}>
					{this.__renderTitle()}
				</Grid>
				<Grid item xs={8} sm={4} component={Box}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					{this.props.children}
					{  this.props.loading && <CircularProgress className={classes.progress}/> }
				</Grid>
				<Grid item container xs={12} justifyContent="flex-end">
					<Grid item xs={8} sm={4} >
						<Divider />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{this.__renderFooter()}
				</Grid>
			</Grid>
		);
	}

	__renderFooter = () => {
		
		return (
			<Grid
				item
				container
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				>
					{/* MODIFY 8 FOR 5 */}
				<Grid item xs={5}> 
					<ErrorText>{this.props.errorMessage}</ErrorText>
				</Grid>
				<Grid
					item
					container
					xs
					spacing={2}
					justifyContent="flex-end">
					<Grid item>
						<Button
							onClick={() => (this.props.onPressCancel ? this.props.onPressCancel() : null)}
							disabled={!this.props.onPressCancel}
							variant="contained"
							color="error">
							<CloseIcon />
						</Button>
					</Grid>
					<Grid item>
						<Button
							disabled={!this.props.onPressCreate}
							onClick={() => (this.props.onPressCreate ? this.props.onPressCreate() : null)}
							variant="contained"
							color="secondary">
							<ArrowForwardIcon />
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	__renderTitle = () => {
		return (
				<Box px={2} py={1}>
					<Typography variant="h3" color="primary">{this.props.title}</Typography>
				</Box>
		)
	}


}
export default withStyles(styles)(CreateSomethingWrapper);
