import {
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import ErrorDialog, { IErrorInformation } from "../../components/errorDialog";
import InformativeDialog, {
    IInformative,
} from "../../components/informativeDialog";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Roles, User } from "../../rebrain-irm-model";
import { WithApolloClient, withApollo } from "@apollo/react-hoc";
import { WithTranslation, withTranslation } from "react-i18next";

import { ActionLogsRouter } from "router/dashboard/logs/action-logs.router";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import BugReportIcon from "@mui/icons-material/BugReport";
import { CreateHospitalCenterRouter } from "../../router/dashboard/createHospitalCenter/createHospitalCenter.router";
import { CreatePatientRecordRouter } from "../../router/dashboard/createPatientRecord/createPatientRecord.router";
import { CreateUserRouter } from "../../router/dashboard/createUser/createUser.router";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FolderIcon from "@mui/icons-material/Folder";
import { GraphQLError } from "graphql";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import { HospitalCentersRouter } from "../../router/dashboard/hospitalCenters/hospitalCenters.router";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { LoginRouter } from "../../router";
import { PatientRecordsRouter } from "../../router/dashboard/patientRecords/patientRecords.router";
import React from "react";
import RegionIcon from "components/regions/regionIcon";
import { RegionsRouter } from "router/dashboard/regions/regions.router";
import SettingsIcon from "@mui/icons-material/Settings";
import { SettingsRouter } from "../../router/dashboard/settings/settings.router";
import { UserRequests } from "../../apollo";
import { UsersRouter } from "../../router/dashboard/users/users.router";

interface IState {
    user?: User;

    information?: IInformative;
    error?: IErrorInformation;
}

interface IProps extends RouteComponentProps, WithTranslation {
    open: boolean;
    onClose?: () => void;

    initialcurrentRoute?: string;
}

class SideBar extends React.Component<WithApolloClient<IProps>, IState> {
    constructor(props: WithApolloClient<IProps>) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        this.__loadUserData();
    };
    render() {
        let list: JSX.Element | undefined = undefined;

        switch (this.state.user?.role) {
            case Roles.CLINICIAN:
                list = this.__renderClinicianList();
                break;
            case Roles.OPERATOR:
                list = this.__renderOperatorList();
                break;
            case Roles.ADMIN:
                list = this.__renderAdminList();
                break;
        }
        return (
            <Container>
                <Drawer open={this.props.open} onClose={this.props.onClose}>
                    <Box m={3}>
                        {/* <Avatar src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80" /> */}
                        <Typography variant="h6">
                            {this.state.user?.lastname}{" "}
                            {this.state.user?.firstname}
                        </Typography>
                        <Typography variant="body1">
                            {this.state.user?.email}
                        </Typography>
                        <Divider variant="middle" />
                        {list}
                        {this.__renderLogout()}
                    </Box>
                </Drawer>
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => {}}
                />
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
            </Container>
        );
    }

    __renderClinicianList = () => {
        const currentRoute = this.props.location.pathname;

        return (
            <List component="nav">
                <ListItem
                    component={Link}
                    to={PatientRecordsRouter.getRoute()}
                    selected={currentRoute === PatientRecordsRouter.getRoute()}
                    onClick={() =>
                        this.__onClickItem(PatientRecordsRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("patient records")} />
                </ListItem>

                <ListItem
                    component={Link}
                    to={CreatePatientRecordRouter.getRoute()}
                    selected={
                        currentRoute === CreatePatientRecordRouter.getRoute()
                    }
                    onClick={() =>
                        this.__onClickItem(CreatePatientRecordRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <AddBoxIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={this.props.t("create patient record")}
                    />
                </ListItem>
            </List>
        );
    };

    __renderOperatorList = () => {
        const currentRoute = this.props.location.pathname;

        return (
            <List component="nav">
                <ListItem
                    component={Link}
                    to={PatientRecordsRouter.getRoute()}
                    selected={currentRoute === PatientRecordsRouter.getRoute()}
                    onClick={() =>
                        this.__onClickItem(PatientRecordsRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("patient records")} />
                </ListItem>
            </List>
        );
    };

    __renderAdminList = () => {
        const currentRoute = this.props.location.pathname;
        return (
            <List component="nav">
                <ListItem
                    component={Link}
                    to={PatientRecordsRouter.getRoute()}
                    selected={currentRoute === PatientRecordsRouter.getRoute()}
                    onClick={() =>
                        this.__onClickItem(PatientRecordsRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("patient records")} />
                </ListItem>

                {/* <ListItem
					component={Link}
					to={CreatePatientRecordRouter.getRoute()}
					selected={currentRoute === CreatePatientRecordRouter.getRoute()}
					onClick={() => this.__onClickItem(CreatePatientRecordRouter.getRoute())}
					>
					<ListItemIcon>
						<AddBoxIcon/>
					</ListItemIcon>
					<ListItemText primary={this.props.t('create patient record')}/>
				</ListItem> */}
                <Typography variant="h6">
                    {this.props.t("administrator")}
                </Typography>
                <ListItem
                    component={Link}
                    to={UsersRouter.getRoute()}
                    selected={currentRoute === UsersRouter.getRoute()}
                    onClick={() => this.__onClickItem(UsersRouter.getRoute())}
                >
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("users")} />
                </ListItem>

                <ListItem
                    component={Link}
                    to={CreateUserRouter.getRoute()}
                    selected={currentRoute === CreateUserRouter.getRoute()}
                    onClick={() =>
                        this.__onClickItem(CreateUserRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("create an user")} />
                </ListItem>

                <ListItem
                    component={Link}
                    to={HospitalCentersRouter.getRoute()}
                    selected={currentRoute === HospitalCentersRouter.getRoute()}
                    onClick={() =>
                        this.__onClickItem(HospitalCentersRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("hospitals")} />
                </ListItem>

                <ListItem
                    component={Link}
                    to={CreateHospitalCenterRouter.getRoute()}
                    selected={
                        currentRoute === CreateHospitalCenterRouter.getRoute()
                    }
                    onClick={() =>
                        this.__onClickItem(
                            CreateHospitalCenterRouter.getRoute()
                        )
                    }
                >
                    <ListItemIcon>
                        <AddLocationIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={this.props.t("create an hospital")}
                    />
                </ListItem>

                <ListItem
                    component={Link}
                    to={RegionsRouter.getRoute()}
                    selected={currentRoute === RegionsRouter.getRoute()}
                    onClick={() => this.__onClickItem(RegionsRouter.getRoute())}
                >
                    <ListItemIcon>
                        <RegionIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("menu.regions")} />
                </ListItem>

                <ListItem
                    component={Link}
                    to={ActionLogsRouter.getRoute()}
                    selected={currentRoute === ActionLogsRouter.getRoute()}
                    onClick={() =>
                        this.__onClickItem(ActionLogsRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <BugReportIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("Logs")} />
                </ListItem>

                <ListItem
                    component={Link}
                    to={SettingsRouter.getRoute()}
                    selected={currentRoute === SettingsRouter.getRoute()}
                    onClick={() =>
                        this.__onClickItem(SettingsRouter.getRoute())
                    }
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={this.props.t("settings")} />
                </ListItem>
            </List>
        );
    };

    __renderLogout = () => {
        return (
            <ListItem>
                <Button
                    fullWidth
                    onClick={() => this.__onClickLogout()}
                    variant="contained"
                    color="error"
                    startIcon={<ExitToAppIcon />}
                >
                    {this.props.t("logout")}
                </Button>
            </ListItem>
        );
    };

    __onClickItem = (route: string, close: boolean | undefined = true) => {
        if (close && this.props.onClose) this.props.onClose();
    };

    __onClickLogout = () => {
        this.setState({
            information: {
                title: this.props.t("disconnection"),
                showProgress: true,
            },
        });

        UserRequests.logout()
            .then(() => {
                setTimeout(() => {
                    this.setState(
                        { information: undefined, error: undefined },
                        () => this.props.history.push(LoginRouter.getRoute())
                    );
                }, 2000);
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t("error"),
                        message: err.message,
                    },
                });
            });
    };

    __loadUserData = () => {
        UserRequests.meBasic()
            .then((user: User) => this.setState({ user }))
            .catch();
    };
}

export default withTranslation()(
    withRouter(withApollo<WithApolloClient<IProps>, IState>(SideBar))
);
