// IMPORTANT: If you define new error codes in `createRegion` you will have to manually update the
// corresponding translation keys in order to display a user-friendly message.
import * as yup from "yup";

import { Box, Button, InputLabel, OutlinedInput } from "@mui/material";
import {
    ErrorMessage,
    Form,
    Formik,
    FormikHelpers,
    useFormikContext,
} from "formik";
import { useCallback, useMemo, useState } from "react";

import AutocompleteEmailInput from "components/commercials/commercialEmailInput";
import CommercialIcon from "components/commercials/commercialIcon";
import ErrorText from "components/errorText";
import { TFunction } from "i18next";
import { isApolloError } from "@apollo/client";
import { useCreateCommercial } from "apollo/CommercialRequests";
import useSnackbar from "hooks/useSnackbar";
import { useTranslation } from "react-i18next";

const MIN_NAME_LEN = 3;
const MAX_NAME_LEN = 255;

const INITIAL_VALUES = {
    _commercialId_: "",
    firstname: "",
    lastname: "",
};

type FormType = typeof INITIAL_VALUES;

function CreateValidation(t: TFunction) {
    return yup.object({
        firstname: yup
            .string()
            .min(
                MIN_NAME_LEN,
                t("commercials.form.firstname.minLength", {
                    count: MIN_NAME_LEN,
                })
            )
            .max(
                MAX_NAME_LEN,
                t("commercials.form.firstname.maxLength", {
                    count: MAX_NAME_LEN,
                })
            )
            .required(t("commercials.form.firstname.required")),
        lastname: yup
            .string()
            .min(
                MIN_NAME_LEN,
                t("commercials.form.lastname.minLength", {
                    count: MIN_NAME_LEN,
                })
            )
            .max(
                MAX_NAME_LEN,
                t("commercials.form.lastname.maxLength", {
                    count: MAX_NAME_LEN,
                })
            )
            .required(t("commercials.form.lastname.required")),
        _commercialId_: yup
            .string()
            .email(t("commercials.form.email.invalid"))
            .required(t("commercials.form.email.required")),
    });
}

type CreateCommercialFormProps = {
    regionId?: number | null | undefined;
    regionName?: string | null | undefined;
};

/** This form that allows to create regions. It does two things:
 *
 * 1. It sends a request to the server to create a new region using the form data.
 * 2. It also displays errors:
 *  - client form validation errors (basic field validation)
 *  - server validation errors (more complex errors, like duplicate region name for example) */
export default function CreateCommercialForm(props: CreateCommercialFormProps) {
    const { t } = useTranslation();
    const { openInformativeSnackbar } = useSnackbar();
    const validationSchema = useMemo(() => CreateValidation(t), [t]);
    const [addCommercialToRegion] = useCreateCommercial(props.regionId);

    const onSubmit = useCallback(
        async (values: FormType, formik: FormikHelpers<FormType>) => {
            try {
                console.log(
                    "Adding commercial",
                    values._commercialId_,
                    "to region",
                    props.regionId
                );
                await addCommercialToRegion({
                    variables: {
                        commercialData: {
                            regionId: props.regionId,
                            firstname: values.firstname,
                            lastname: values.lastname,
                            email: values._commercialId_,
                        },
                    },
                });
                await openInformativeSnackbar({
                    message: t("commercials.form.success", {
                        email: values._commercialId_,
                    }),
                    icon: <CommercialIcon />,
                });
                formik.resetForm();
                formik.setFieldValue("_commercialId_", "");
            } catch (error) {
                if (error instanceof Error && isApolloError(error)) {
                    formik.setErrors({
                        _commercialId_: t(
                            `commercials.form.serverError.${error.message}`
                        ),
                    });
                }
            }
        },
        [addCommercialToRegion, openInformativeSnackbar, props.regionId, t]
    );

    if (props.regionId == null) {
        return <p>{t("commercials.form.noRegionSelected")}</p>;
    }

    return (
        <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            <FormContent
                regionId={props.regionId}
                regionName={props.regionName}
            />
        </Formik>
    );
}

function FormContent(props: { regionId: number; regionName?: string | null }) {
    const { t } = useTranslation();
    const formik = useFormikContext<FormType>();

    const [pickedExistingEmail, setPickedExistingEmail] = useState(false);

    return (
        <Form autoComplete="off">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <Box sx={{ minWidth: 300 }}>
                    <InputLabel htmlFor="_commercialId_">
                        {t("commercials.form.email.label")
                            // Add zero-width spaces to prevent email autofill on chrome
                            // (when 'email' appears, chrome think it should autofill the
                            // current chrome user's email, which is not what we want here)
                            .replace("Email", "E\u200Bm\u200Ba\u200Bi\u200Bl")
                            .replace("email", "e\u200Bm\u200Ba\u200Bi\u200Bl")}
                    </InputLabel>
                    <AutocompleteEmailInput<FormType>
                        id="_commercialId_"
                        name="_commercialId_"
                        value={formik.values._commercialId_}
                        regionId={props.regionId}
                        onPickedExistingEmail={() =>
                            setPickedExistingEmail(true)
                        }
                        onPickedNewEmail={() => setPickedExistingEmail(false)}
                    />
                    <InputLabel htmlFor="firstname">
                        {t("commercials.form.firstname.label")}
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        name="firstname"
                        id="firstname"
                        type="text"
                        disabled={formik.status?.wasAutocompleted}
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.firstname &&
                            Boolean(formik.errors.firstname)
                        }
                        // `one-time-code` seems odd, but that's the only way I found
                        // to disable any autofill on chrome, ref:
                        // https://stackoverflow.com/a/30976223
                        inputProps={{ autoComplete: "one-time-code" }}
                    />
                    <InputLabel htmlFor="lastname">
                        {t("commercials.form.lastname.label")}
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        name="lastname"
                        id="lastname"
                        type="text"
                        disabled={formik.status?.wasAutocompleted}
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.lastname &&
                            Boolean(formik.errors.lastname)
                        }
                        // `one-time-code` seems odd, but that's the only way I found
                        // to disable any autofill on chrome, ref:
                        // https://stackoverflow.com/a/30976223
                        inputProps={{ autoComplete: "one-time-code" }}
                    />
                </Box>
                <Box
                    sx={{
                        minHeight: 50,
                        py: 2,
                        px: 4,
                        minWidth: 400,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ flexGrow: 1, alignContent: "center" }}>
                        <ErrorMessage
                            name="_commercialId_"
                            component={ErrorText}
                        />
                        <ErrorMessage name="firstname" component={ErrorText} />
                        <ErrorMessage name="lastname" component={ErrorText} />
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{ px: 4, py: 1 }}
                    >
                        {pickedExistingEmail
                            ? t("commercials.form.submit.link", {
                                  regionName: props.regionName,
                              })
                            : t("commercials.form.submit.create", {
                                  regionName: props.regionName,
                              })}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
}
